<template>
    <div v-if="!initialized">loading...</div>
    <div v-if="initialized && getFileDataSuccess" id="editorView">
        <component :is="currentComponent" :fileId="$route.query.fileId" :fileName="$route.query.fileName" :content="docContent" :mode="mode" :templateId="templateId"/>
    </div>
    <div v-if="initialized && !getFileDataSuccess">
      加载文件内容失败
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import config from "../config";
    import {VARIABLE_TYPE_OPTIONS} from "../constant/question";
    import {getMetaProData,requestHeaderMixin} from "../api/api";
    import {isMobile} from "../util";

    export default {
        name: "EditorInitializer",
        mixins:[requestHeaderMixin],
        data() {
            return {
                initialized: false,
                docContent: null,
                docType: null,
                mode: 'edit_model',
                getFileDataSuccess: true,
                templateId:null,
            }
        },
      mounted() {
            if (this.$route.query.mode === 'answer_model') {
                this.mode = this.$route.query.mode;
            }
            if(this.$route.query.templateId && this.$route.query.templateId!=''){
              this.templateId=this.$route.query.templateId;
            }
            // todo 这里需要根据不同的文件类型调用不同的接口，方便进行权限控制，也就说该页面应该传一个type过来。
            getMetaProData(this.$route.query.fileId).then(res => {
                console.log('getFileMetaProData  res === ', res);
                const {code, data} = res.data;
                this.initialized = true;
                if (code === 0) {
                  let docContent = data;
                  this.docType = docContent.type
                  this.docContent = this.fixTemplateOldData(docContent.type,docContent);
                  console.log(this.docContent)
                  this.getFileDataSuccess = true;
                } else {
                  this.getFileDataSuccess = false;
                  if (code === 403) {
                    // 没权限
                    window.location.href = config.baseUrl;
                  }
                }
            });
        },
        computed: {
            currentComponent() {
                console.log('EditorInitializer  this.docType ==== ', this.docType);
                if (this.docType === 'template') {
                  if(isMobile()){
                    return 'MobileDocTemplate';
                  }else{
                    return 'DocTemplate';
                  }
                }
            },
        },
        components: {
            DocTemplate: defineAsyncComponent(() => import(/* webpackChunkName: "template-editor" */'./DocTemplate')),
            MobileDocTemplate: defineAsyncComponent(()=> import('./mobileViews/template/MobileDocTemplate'))
        },
      methods:{
        fixTemplateOldData:function (docType,docContent) {
          if (docType !== 'template') {
            return docContent;
          }
          if(!docContent.variableRects){
            return docContent;
          }
          Object.keys(docContent.variableRects).forEach((key)=>{
            let rect = docContent.variableRects[key];
            if (!rect || VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value !== rect.type) {
              return;
            }
            if (typeof rect.standardAnswer === 'string') {
              rect.standardAnswer = [rect.standardAnswer]
            }
          })
          return docContent;
        }
      }
    }
</script>

<style>
 #editorView{
   position: relative;
   min-height: 100vh;
   /*width: 98vw;*/
   width:100%;
 }
 ::-webkit-scrollbar {
    display: none;
 }
.el-icon-loading {
    font-size: 30px;
}
</style>
